import * as React from "react";
import Layout from "../_components/Layout/Layout";
import MainTitle from "../_components/MainTitle/MainTitle";

const Contacts = () => {
    return (
        <Layout title="Contacts">
            <MainTitle className={'contacts'}>Contact us</MainTitle>

            <div className="contact-us">
                <span>Contact us on glidebattersea@help.xela.co.</span>

                <div className="contact-email">
                    <a href={`mailto:${process.env.GATSBY_CONTACT_EMAIL}`}>
                        Contact us
                    </a>
                </div>
            </div>
        </Layout>
    );
};

export default Contacts;
